import { GridSortItem } from "@mui/x-data-grid";
import dayjs from "dayjs";

const serverUrl = "https://www.johnspasscondominiums.com:12345";

export interface SessionStateHandler {
	sessionState: SessionState;
	setSessionState: (arg0: SessionState) => void;
}

export enum QueryStatus {
	IDLE,
	LOADING,
	SUCCESS,
	FAILURE,
}

export enum SessionStatus {
	LOGGED_OUT,
	LOGGED_IN,
	EXPIRED,
}

export interface SessionState {
	status: SessionStatus;
	user?: {
		username: string;
		permissionFlags: [];
	};
	token?: string;
}

export interface Property {
	_id: string;
	"Property Name": string;
	"Owner Name": string;
	"Owner Phone": string;
}

export interface Detail {
	_id: string;
	archived: boolean;
	propertyId: string;
	inspectionDate: string;
	inspector: string;
	ertsDate: string | null;
	status: string;
	notes: string;
	images: string[];
}

export interface SettingsObject {
	_id: string;
	statusList: string[];
	inspectorList: string[];
}

function wrapSignature(content: object, sessionState: SessionState) {
	return JSON.stringify({
		content,
		token: sessionState.token,
	});
}

export async function postAction(
	content: object,
	sessionState: SessionState,
	setSessionState: (arg0: SessionState) => void,
	onSuccess?: (arg0: { [id: string]: any }) => void,
	onFailure?: (e: any) => void
) {
	try {
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		if (onSuccess) {
			onSuccess(res);
		}
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		}

		if (onFailure) {
			onFailure(e);
		} else {
			throw e;
		}
	}
}

export async function getSettings(sessionState: SessionState, setSessionState: (arg0: SessionState) => void) {
	try {
		const content = {
			action: "get-settings",
		};
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		return res;
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		} else {
			throw e;
		}
	}
}

export async function getData(query: object, sessionState: SessionState, setSessionState: (arg0: SessionState) => void) {
	try {
		const content = {
			action: "get-property-list",
			query,
		};
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		return res;
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		} else {
			throw e;
		}
	}
}

export async function getDetails(query: object, sessionState: SessionState, setSessionState: (arg0: SessionState) => void) {
	try {
		const content = {
			action: "get-details",
			query,
		};
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		return res;
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		} else {
			throw e;
		}
	}
}

export async function getProperty(id: string, sessionState: SessionState, setSessionState: (arg0: SessionState) => void) {
	try {
		const content = {
			action: "get-property",
			id,
		};
		const res = await (
			await fetch(serverUrl, {
				method: "post",
				body: wrapSignature(content, sessionState),
			})
		).json();
		if (res?.status === "Failure") {
			throw res?.reason;
		}
		return res;
	} catch (e) {
		if (e === "Expired Token") {
			setSessionState({
				status: SessionStatus.EXPIRED,
			});
		} else {
			throw e;
		}
	}
}

export function sortPropertyListData(sortModel: GridSortItem[], data: Property[], details: { [id: string]: Detail[] }) {
	let sortedData = [...data];
	for (let e of sortModel) {
		switch (e.field) {
			case "propertyName":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a, b) => a["Property Name"].localeCompare(b["Property Name"]));
						break;
					case "desc":
						sortedData.sort((a, b) => -a["Property Name"].localeCompare(b["Property Name"]));
						break;
				}
				break;
			case "inspectionDate":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => {
							const lastDetailsA = details?.[a["_id"]]?.[0] || {};
							const lastDetailsB = details?.[b["_id"]]?.[0] || {};
							if (lastDetailsA.inspectionDate === undefined && lastDetailsB.inspectionDate === undefined) {
								return 0;
							}
							if (lastDetailsA.inspectionDate === undefined) {
								return -1;
							}
							if (lastDetailsB.inspectionDate === undefined) {
								return 1;
							}
							return dayjs(lastDetailsA.inspectionDate).diff(dayjs(lastDetailsB.inspectionDate));
						});
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => {
							const lastDetailsA = details?.[a["_id"]]?.[0] || {};
							const lastDetailsB = details?.[b["_id"]]?.[0] || {};
							if (lastDetailsA.inspectionDate === undefined && lastDetailsB.inspectionDate === undefined) {
								return 0;
							}
							if (lastDetailsA.inspectionDate === undefined) {
								return 1;
							}
							if (lastDetailsB.inspectionDate === undefined) {
								return -1;
							}
							return -dayjs(lastDetailsA.inspectionDate).diff(dayjs(lastDetailsB.inspectionDate));
						});
						break;
				}
				break;
			case "inspector":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => {
							const lastDetailsA = details?.[a["_id"]]?.[0] || {};
							const lastDetailsB = details?.[b["_id"]]?.[0] || {};
							if (lastDetailsA.inspector === undefined && lastDetailsB.inspector === undefined) {
								return 0;
							}
							if (lastDetailsA.inspector === undefined) {
								return -1;
							}
							if (lastDetailsB.inspector === undefined) {
								return 1;
							}
							return lastDetailsA.inspector.localeCompare(lastDetailsB.inspector);
						});
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => {
							const lastDetailsA = details?.[a["_id"]]?.[0] || {};
							const lastDetailsB = details?.[b["_id"]]?.[0] || {};
							if (lastDetailsA.inspector === undefined && lastDetailsB.inspector === undefined) {
								return 0;
							}
							if (lastDetailsA.inspector === undefined) {
								return 1;
							}
							if (lastDetailsB.inspector === undefined) {
								return -1;
							}
							return -lastDetailsA.inspector.localeCompare(lastDetailsB.inspector);
						});
						break;
				}
				break;
			case "ertsDate":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => {
							const lastDetailsA = details?.[a["_id"]]?.[0] || {};
							const lastDetailsB = details?.[b["_id"]]?.[0] || {};
							if (lastDetailsA.ertsDate === undefined && lastDetailsB.ertsDate === undefined) {
								return 0;
							}
							if (lastDetailsA.ertsDate === undefined) {
								return -1;
							}
							if (lastDetailsB.ertsDate === undefined) {
								return 1;
							}
							return dayjs(lastDetailsA.ertsDate).diff(dayjs(lastDetailsB.ertsDate));
						});
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => {
							const lastDetailsA = details?.[a["_id"]]?.[0] || {};
							const lastDetailsB = details?.[b["_id"]]?.[0] || {};
							if (lastDetailsA.ertsDate === undefined && lastDetailsB.ertsDate === undefined) {
								return 0;
							}
							if (lastDetailsA.ertsDate === undefined) {
								return 1;
							}
							if (lastDetailsB.ertsDate === undefined) {
								return -1;
							}
							return -dayjs(lastDetailsA.ertsDate).diff(dayjs(lastDetailsB.ertsDate));
						});
						break;
				}
				break;
			case "status":
				switch (e.sort) {
					case "asc":
						sortedData.sort((a: any, b: any) => {
							const lastDetailsA = details?.[a["_id"]]?.[0] || {};
							const lastDetailsB = details?.[b["_id"]]?.[0] || {};
							if (lastDetailsA.status === undefined && lastDetailsB.status === undefined) {
								return 0;
							}
							if (lastDetailsA.status === undefined) {
								return -1;
							}
							if (lastDetailsB.status === undefined) {
								return 1;
							}
							return lastDetailsA.status.localeCompare(lastDetailsB.status);
						});
						break;
					case "desc":
						sortedData.sort((a: any, b: any) => {
							const lastDetailsA = details?.[a["_id"]]?.[0] || {};
							const lastDetailsB = details?.[b["_id"]]?.[0] || {};
							if (lastDetailsA.status === undefined && lastDetailsB.status === undefined) {
								return 0;
							}
							if (lastDetailsA.status === undefined) {
								return 1;
							}
							if (lastDetailsB.status === undefined) {
								return -1;
							}
							return -lastDetailsA.status.localeCompare(lastDetailsB.status);
						});
						break;
				}
				break;
		}
	}
	return sortedData;
}
