import { Add, Download, Settings } from "@mui/icons-material";
import { Alert, AppBar, Box, IconButton, Modal, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridSortItem } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AddProperty } from "./AddProperty";
import { Details } from "./Details";
import { SettingsTab } from "./SettingsTab";
import { Detail, getData, getDetails, Property, SessionStateHandler, SessionStatus, SettingsObject, sortPropertyListData } from "./utils";

function getRowData(properties: Property[], details: { [id: string]: Detail[] }) {
	return properties.map((property) => {
		const lastDetail = details?.[property._id]?.[0] || {};
		return {
			id: property._id,
			propertyName: property["Property Name"],
			inspectionDate: lastDetail.inspectionDate,
			inspector: lastDetail.inspector,
			ertsDate: lastDetail.ertsDate,
			status: lastDetail.status,
			notes: lastDetail.notes,
		};
	});
}

interface PropertiesProps extends SessionStateHandler {
	settings: SettingsObject;
	refetchSettings: () => void;
}

export function Properties({ sessionState, setSessionState, settings, refetchSettings }: PropertiesProps) {
	const [openSettingsModal, setOpenSettingsModal] = useState(false);
	const [detailsModal, setDetailsModal] = useState<string | null>(null);
	const [sortModel, setSortModel] = useState<GridSortItem[]>([{ field: "propertyName", sort: "asc" }]);
	const [searchTerm, setSearchTerm] = useState("");
	const [addPropertyDialog, setAddPropertyDialog] = useState(false);

	const [csvFile, setCsvFile] = useState<Blob | undefined>(undefined);
	const [csvFileDate, setCsvFileDate] = useState<string | undefined>(undefined);

	const dataQuery = useQuery({
		queryKey: ["data"],
		queryFn: () => getData({}, sessionState, setSessionState),
		enabled: true,
	});

	const dataIds = (dataQuery.data || []).map((datum: Property) => datum["_id"]).sort();

	const detailsQuery = useQuery({
		queryKey: ["details", JSON.stringify(dataIds)],
		queryFn: () =>
			getDetails(
				{
					archived: false,
					propertyIds: dataIds,
				},
				sessionState,
				setSessionState
			),
		enabled: true,
	});

	useEffect(() => {
		if (sessionState.status === SessionStatus.LOGGED_IN) {
			dataQuery.refetch();
		}
	}, [sessionState]);

	const isLoading = dataQuery.isFetching || detailsQuery.isFetching;
	const isError = dataQuery.isError || detailsQuery.isError;

	const data = (dataQuery.data || []).filter((property: Property) => property["Property Name"].toLowerCase().includes(searchTerm.toLowerCase()));

	useEffect(() => {
		const details = detailsQuery.data || {};
		const text = sortPropertyListData(sortModel, data, details)
			.map((datum: Property) => {
				const lastDetail: Detail = details?.[datum._id]?.[0] || {};
				return [
					datum["Property Name"],
					lastDetail.inspectionDate === undefined ? "" : dayjs(lastDetail.inspectionDate).format("MM/DD/YYYY hh:mm A"),
					lastDetail.inspector || "",
					lastDetail.status || "",
					lastDetail.ertsDate === undefined ? "" : dayjs(lastDetail.ertsDate).format("MM/DD/YYYY hh:mm A"),
					lastDetail.notes || "",
				]
					.map((v) => (v.includes(",") ? `"${v}"` : v))
					.join(",");
			})
			.join("\n");
		const file = new Blob([`Property Name,Inspection Date,Inspector,Status,Expected Return-to-Service Date,Notes\n${text}`], { type: "text/plain" });
		setCsvFile(file);
		setCsvFileDate(dayjs(Date.now()).format("MM-DD-YYYY hh:mm A"));
	}, [sortModel, dataQuery.data, detailsQuery.data, searchTerm]);

	return (
		<>
			<AppBar color="inherit">
				<Toolbar>
					<IconButton onClick={() => setAddPropertyDialog(true)}>
						<Add />
					</IconButton>
					<TextField
						value={searchTerm}
						color="primary"
						sx={{ m: 1 }}
						size="small"
						label="Search Properties"
						onChange={(event) => setSearchTerm(event.target.value)}
					/>
					<Typography variant="h6" color="inherit" component="div" sx={{ m: 1, flex: 1, textAlign: "left" }}>
						Property List
					</Typography>
					<Tooltip title="Download CSV">
						<IconButton
							download={`PROPERTIES (${csvFileDate}).csv`}
							target="_blank"
							rel="noreferrer"
							disabled={csvFile === undefined}
							sx={{ color: "inherit" }}
							href={csvFile !== undefined ? URL.createObjectURL(csvFile) : ""}
						>
							<Download />
						</IconButton>
					</Tooltip>
					<Tooltip title="Edit Settings">
						<IconButton sx={{ color: "inherit" }} onClick={() => setOpenSettingsModal(true)}>
							<Settings />
						</IconButton>
					</Tooltip>
				</Toolbar>
				{isError && (
					<Alert variant="filled" severity="error">
						An error occurred, try refreshing the page.
					</Alert>
				)}
			</AppBar>
			<Box sx={{ marginTop: "64px" }}>
				<div id="datagrid-start" />
				<Box
					sx={{
						p: 1,
						height: `calc(100vh - ${document.getElementById("datagrid-start")?.getBoundingClientRect().top}px - 20px)`,
						minHeight: "300px",
					}}
				>
					<DataGrid
						sx={{
							"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
								outline: "none !important",
							},
						}}
						initialState={{
							sorting: {
								sortModel: [{ field: "clientName", sort: "asc" }],
							},
						}}
						pageSizeOptions={[10, 25, 100]}
						disableColumnMenu
						loading={isLoading}
						onRowClick={(params) => setDetailsModal(params.row.id)}
						onSortModelChange={(model) => setSortModel(model)}
						columns={[
							{
								field: "propertyName",
								headerName: "Property Name",
								flex: 2,
								filterable: false,
								hideable: false,
								minWidth: 200,
							},
							{
								field: "inspectionDate",
								headerName: "Inspection Date",
								flex: 1,
								filterable: false,
								hideable: false,
								minWidth: 200,
								sortComparator: (a, b) => {
									if (a === undefined && b === undefined) {
										return 0;
									}
									if (a === undefined) {
										return -1;
									}
									if (b === undefined) {
										return 1;
									}
									return dayjs(b).diff(dayjs(a));
								},
								renderCell: (params) =>
									params.value === undefined ? (
										<span style={{ color: "grey" }}>N/A</span>
									) : (
										<span>{dayjs(params.value).format("MM/DD/YYYY hh:mm A")}</span>
									),
							},
							{
								field: "inspector",
								headerName: "Inspector",
								flex: 1,
								filterable: false,
								hideable: false,
								minWidth: 100,
							},
							{
								field: "status",
								headerName: "Status",
								flex: 1,
								filterable: false,
								hideable: false,
								minWidth: 100,
							},
							{
								field: "ertsDate",
								headerName: "Expected Return Date",
								flex: 1,
								filterable: false,
								hideable: false,
								minWidth: 200,
								sortComparator: (a, b) => {
									if (a === undefined && b === undefined) {
										return 0;
									}
									if (a === undefined) {
										return -1;
									}
									if (b === undefined) {
										return 1;
									}
									return dayjs(b).diff(dayjs(a));
								},
								renderCell: (params) =>
									params.value === undefined ? (
										<span style={{ color: "grey" }}>N/A</span>
									) : (
										<span>{dayjs(params.value).format("MM/DD/YYYY hh:mm A")}</span>
									),
							},
							{
								field: "notes",
								headerName: "Notes",
								flex: 2,
								sortable: false,
								filterable: false,
								hideable: false,
								minWidth: 200,
							},
						]}
						rows={getRowData(data, detailsQuery.data || {})}
					/>
				</Box>
			</Box>
			<Modal open={openSettingsModal} onClose={() => setOpenSettingsModal(false)}>
				<Box
					sx={{
						position: "absolute",
						top: "15px",
						maxHeight: "calc(100% - 30px)",
						left: "30px",
						right: "30px",
						boxShadow: 24,
						background: (theme) => theme.palette.background.paper,
						overflow: "auto",
						borderRadius: "10px",
					}}
				>
					<SettingsTab
						settings={settings}
						onClose={() => {
							setOpenSettingsModal(false);
							dataQuery.refetch();
						}}
						sessionState={sessionState}
						setSessionState={setSessionState}
						refetchSettings={refetchSettings}
					/>
				</Box>
			</Modal>
			<Details
				settings={settings}
				detailsModal={detailsModal !== null}
				id={detailsModal || ""}
				onClose={() => {
					setDetailsModal(null);
					dataQuery.refetch();
					detailsQuery.refetch();
				}}
				sessionState={sessionState}
				setSessionState={setSessionState}
			/>
			<AddProperty
				isOpen={addPropertyDialog}
				onClose={(id) => {
					setAddPropertyDialog(false);
					dataQuery.refetch();
					if (id) {
						setDetailsModal(id);
					}
				}}
				sessionState={sessionState}
				setSessionState={setSessionState}
			/>
		</>
	);
}
