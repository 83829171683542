import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { Add, Close, Delete, DragIndicator, Restore } from "@mui/icons-material";
import { Alert, AppBar, Box, Button, IconButton, LinearProgress, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import uuid from "react-uuid";
import { QueryStatus, SessionStateHandler, SettingsObject, postAction } from "./utils";

interface SettingsTabProps extends SessionStateHandler {
	onClose: () => void;
	settings: SettingsObject;
	refetchSettings: () => void;
}

interface DraggableListProps {
	listId: string;
	list: { value: string; id: string }[];
	setList: (arg0: { value: string; id: string }[]) => void;
}

function areArraysEqual(a: string[], b: string[]) {
	if (a === undefined && b !== undefined) {
		return false;
	}
	if (a !== undefined && b === undefined) {
		return false;
	}
	if (a === undefined && b === undefined) {
		return true;
	}

	if (a.length !== b.length) {
		return false;
	}

	for (let i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) {
			return false;
		}
	}

	return true;
}

function DraggableList({ listId, list, setList }: DraggableListProps) {
	return (
		<Box
			sx={{
				display: "table",
				border: (theme) => `1px solid ${theme.palette.grey[300]}`,
				borderRadius: "4px",
			}}
		>
			<DragDropContext
				onDragEnd={({ destination, source, draggableId }) => {
					if (!destination) {
						return;
					}
					if (destination.droppableId === source.droppableId && destination.index === source.index) {
						return;
					}
					const newList = Array.from(list);
					const value = list.find(({ id }) => id === draggableId)?.value as string;
					newList.splice(source.index, 1);
					newList.splice(destination.index, 0, { value, id: draggableId });

					setList(newList);
				}}
			>
				<Droppable key={listId} droppableId={listId}>
					{(provided) => (
						<Box sx={{ display: "table" }} ref={provided.innerRef} {...provided.droppableProps}>
							{list.map(({ value, id }, index) => (
								<Draggable key={id} draggableId={id} index={index}>
									{(provided) => (
										<div {...provided.draggableProps} ref={provided.innerRef}>
											<Box
												sx={{
													margin: 0,
													paddingTop: 1,
													paddingBottom: 1,
													backgroundColor: (theme) => theme.palette.grey[100],
													display: "flex",
													alignItems: "center",
													border: (theme) => `0.5px solid ${theme.palette.grey[300]}`,
												}}
											>
												<div {...provided.dragHandleProps}>
													<DragIndicator sx={{ color: (theme) => theme.palette.grey[300], fontSize: "32px" }} />
												</div>
												<TextField
													sx={{ width: "300px" }}
													value={value}
													onChange={(e) => {
														const newList = Array.from(list);
														newList[index] = { value: e.target.value, id };
														setList(newList);
													}}
												/>
												<Tooltip title="Delete">
													<IconButton
														size="small"
														sx={{ margin: 1, float: "right" }}
														onClick={() => {
															const newList = Array.from(list);
															newList.splice(index, 1);
															setList(newList);
														}}
													>
														<Delete />
													</IconButton>
												</Tooltip>
											</Box>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</Box>
					)}
				</Droppable>
			</DragDropContext>
			<Tooltip title="Add New Field">
				<IconButton
					sx={{
						float: "right",
						m: 1,
						backgroundColor: (theme) => theme.palette.primary.main,
					}}
					onClick={() =>
						setList([
							...list,
							{
								id: uuid(),
								value: "",
							},
						])
					}
				>
					<Add />
				</IconButton>
			</Tooltip>
		</Box>
	);
}

export function SettingsTab({ settings, sessionState, setSessionState, refetchSettings, onClose }: SettingsTabProps) {
	const [statusList, setStatusList] = useState<{ value: string; id: string }[]>([]);
	const [inspectorList, setInspectorList] = useState<{ value: string; id: string }[]>([]);

	const [postStatus, setPostStatus] = useState<QueryStatus>(QueryStatus.IDLE);

	const resetSettings = () => {
		setStatusList((settings.statusList || []).map((v: string) => ({ value: v, id: uuid() })) || []);
		setInspectorList((settings.inspectorList || []).map((v: string) => ({ value: v, id: uuid() })) || []);
	};

	useEffect(resetSettings, [settings]);

	const isEdited =
		!areArraysEqual(
			statusList.map(({ value }) => value),
			settings.statusList
		) ||
		!areArraysEqual(
			inspectorList.map(({ value }) => value),
			settings.inspectorList
		);

	const updateAction = () =>
		postAction(
			{
				action: "set-settings",
				id: settings["_id"],
				data: {
					...settings,
					_id: undefined,
					inspectorList: inspectorList.map(({ value }) => value),
					statusList: statusList.map(({ value }) => value),
				},
			},
			sessionState,
			setSessionState,
			() => {
				setPostStatus(QueryStatus.SUCCESS);
				refetchSettings();
			},
			(e) => {
				console.log(e);
				setPostStatus(QueryStatus.FAILURE);
			}
		);

	return (
		<>
			<AppBar color="inherit" position="sticky">
				<Toolbar>
					<Typography variant="h6" color="inherit" component="div" sx={{ flex: 1 }}>
						Settings
					</Typography>
					<Tooltip title="Undo Changes">
						<IconButton sx={{ m: 1 }} disabled={!isEdited} onClick={resetSettings}>
							<Restore />
						</IconButton>
					</Tooltip>
					<Button variant="contained" disabled={!isEdited} onClick={updateAction}>
						Update Settings
					</Button>
					<Tooltip title="Close Window">
						<IconButton onClick={() => onClose()} sx={{ color: "inherit" }}>
							<Close />
						</IconButton>
					</Tooltip>
				</Toolbar>
				{postStatus === QueryStatus.LOADING && <LinearProgress sx={{ position: "absolute", width: "100%", bottom: "-4px" }} />}
				{postStatus === QueryStatus.FAILURE && (
					<Alert variant="filled" severity="error" onClose={() => setPostStatus(QueryStatus.IDLE)}>
						An error occurred, please try again.
					</Alert>
				)}
			</AppBar>
			<Box sx={{ position: "relative", width: "100%" }}>
				<Box sx={{ display: "flex", flexWrap: "wrap" }}>
					<Box sx={{ margin: 2 }}>
						<Typography sx={{ m: 1, color: (theme) => theme.palette.text.primary, fontWeight: "bold" }}>Inspector List</Typography>
						<DraggableList listId="inspectorList" list={inspectorList} setList={setInspectorList} />
					</Box>
					<Box sx={{ margin: 2 }}>
						<Typography sx={{ m: 1, color: (theme) => theme.palette.text.primary, fontWeight: "bold" }}>Status List</Typography>
						<DraggableList listId="statusList" list={statusList} setList={setStatusList} />
					</Box>
				</Box>
			</Box>
		</>
	);
}
