import { ArrowDownward, ArrowUpward, Close, Delete, Image, Restore, Save } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	MenuItem,
	Modal,
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useQuery } from "@tanstack/react-query";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { Detail, getDetails, postAction, QueryStatus, SessionStateHandler, SessionStatus, SettingsObject } from "./utils";

interface DetailsRowProps {
	isLast: boolean;
	detail: Detail;
	settings: SettingsObject;
	deleteDetail: (id: string) => void;
	updateDetail: (detail: Detail) => void;
	declareEdited: (arg0: string, arg1: boolean) => void;
}

function DetailsRow({ isLast, detail, deleteDetail, updateDetail, declareEdited, settings }: DetailsRowProps) {
	const [newInspectionDate, setNewInspectionDate] = useState(dayjs(detail.inspectionDate));
	const [newInspector, setNewInspector] = useState(detail.inspector);
	const [newErtsDate, setNewErtsDate] = useState<Dayjs | null>(detail.ertsDate ? dayjs(detail.ertsDate) : null);
	const [newStatus, setNewStatus] = useState(detail.status);
	const [newNotes, setNewNotes] = useState(detail.notes);

	const isInspectionDateEdited = newInspectionDate.unix() !== dayjs(detail.inspectionDate).unix();
	const isInspectorEdited = newInspector !== detail.inspector;
	const isErtsDateEdited = newErtsDate ? newErtsDate.unix() !== dayjs(detail.ertsDate).unix() : newErtsDate === null && detail.ertsDate === null;
	const isStatusEdited = newStatus !== detail.status;
	const isNotesEdited = newNotes !== detail.notes;
	const isEdited = isInspectionDateEdited || isInspectorEdited || isErtsDateEdited || isStatusEdited || isNotesEdited;

	const [showImagesModal, setShowImagesModal] = useState<string[]>([]);

	useEffect(() => declareEdited(`details`, isEdited), [isEdited]);

	const editable = false;

	return (
		<TableRow
			sx={{
				background: (theme) => theme.palette.background.paper,
				borderBottom: !isLast ? (theme) => `1px solid ${theme.palette.grey[300]}` : "none",
				":hover": { background: (theme) => theme.palette.grey[100] },
			}}
		>
			<TableCell
				sx={{
					p: 0,
					borderRight: (theme) => `1px solid ${theme.palette.grey[100]}`,
					borderBottom: "none",
				}}
			>
				{editable ? (
					<DateTimePicker
						label={isInspectionDateEdited ? dayjs(detail.inspectionDate).format("MM/DD/YYYY") : undefined}
						sx={{ m: 1, width: "220px" }}
						slotProps={{ textField: { size: "small", focused: isInspectionDateEdited, color: isInspectionDateEdited ? "secondary" : "primary" } }}
						value={newInspectionDate}
						onChange={(value) => {
							if (value !== null) {
								setNewInspectionDate(value);
							}
						}}
					/>
				) : (
					<Typography
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "left",
							m: 1,
						}}
					>
						{newInspectionDate ? newInspectionDate.format("MM/DD/YYYY hh:mm A") : "N/A"}
					</Typography>
				)}
			</TableCell>
			<TableCell
				sx={{
					p: 0,
					borderRight: (theme) => `1px solid ${theme.palette.grey[100]}`,
					borderBottom: "none",
				}}
			>
				{editable ? (
					<TextField
						sx={{ m: 1, width: "200px" }}
						size="small"
						select
						focused={isInspectorEdited}
						color={isInspectorEdited ? "secondary" : "primary"}
						label={isInspectorEdited ? detail.inspector : undefined}
						value={newInspector}
						onChange={(e) => setNewInspector(e.target.value)}
					>
						{settings.inspectorList.map((name) => (
							<MenuItem value={name}>{name}</MenuItem>
						))}
					</TextField>
				) : (
					<Typography
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "left",
							m: 1,
						}}
					>
						{newInspector}
					</Typography>
				)}
			</TableCell>
			<TableCell
				sx={{
					p: 0,
					borderRight: (theme) => `1px solid ${theme.palette.grey[100]}`,
					borderBottom: "none",
				}}
			>
				{editable ? (
					<DateTimePicker
						label={isErtsDateEdited ? dayjs(detail.ertsDate).format("MM/DD/YYYY") : undefined}
						sx={{ m: 1, width: "220px" }}
						slotProps={{ textField: { size: "small", focused: isErtsDateEdited, color: isErtsDateEdited ? "secondary" : "primary" } }}
						value={newErtsDate}
						onChange={(value) => {
							if (value !== null) {
								setNewErtsDate(value);
							}
						}}
					/>
				) : (
					<Typography
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "left",
							m: 1,
						}}
					>
						{newErtsDate ? newErtsDate.format("MM/DD/YYYY hh:mm A") : "N/A"}
					</Typography>
				)}
			</TableCell>
			<TableCell
				sx={{
					p: 0,
					borderRight: (theme) => `1px solid ${theme.palette.grey[100]}`,
					borderBottom: "none",
				}}
			>
				{editable ? (
					<TextField
						size="small"
						sx={{ m: 1, width: "200px" }}
						select
						focused={isStatusEdited}
						color={isStatusEdited ? "secondary" : "primary"}
						label={isStatusEdited ? detail.status : undefined}
						value={newStatus}
						onChange={(e) => setNewStatus(e.target.value)}
					>
						{settings.statusList.map((status) => (
							<MenuItem value={status}>{status}</MenuItem>
						))}
					</TextField>
				) : (
					<Typography
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "left",
							m: 1,
						}}
					>
						{newStatus}
					</Typography>
				)}
			</TableCell>
			<TableCell
				sx={{
					p: 0,
					display: "flex",
					borderBottom: "none",
					alignItems: "center",
				}}
			>
				{detail.images && detail.images.length > 0 && (
					<IconButton size="small" sx={{ m: 1 }} onClick={() => setShowImagesModal(detail.images)}>
						<Image />
					</IconButton>
				)}
				{editable ? (
					<>
						<TextField
							size="small"
							sx={{ flex: 1, m: 1, minWidth: "220px" }}
							focused={isNotesEdited}
							color={isNotesEdited ? "secondary" : "primary"}
							label={isNotesEdited ? detail.status : undefined}
							value={newNotes}
							multiline
							onChange={(e) => setNewNotes(e.target.value)}
						/>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<Tooltip title="Save Changes">
								<IconButton
									sx={{ m: 1 }}
									disabled={!isEdited}
									onClick={() =>
										updateDetail({
											_id: detail._id,
											archived: detail.archived,
											propertyId: detail.propertyId,
											inspectionDate: newInspectionDate.toISOString(),
											inspector: newInspector,
											ertsDate: newErtsDate ? newErtsDate.toISOString() : null,
											status: newStatus,
											notes: newNotes,
											images: detail.images,
										})
									}
								>
									<Save />
								</IconButton>
							</Tooltip>
							{isEdited ? (
								<Tooltip title="Undo Changes">
									<IconButton
										sx={{ m: 1 }}
										onClick={() => {
											setNewInspectionDate(dayjs(detail.inspectionDate));
											setNewInspector(detail.inspector);
											setNewErtsDate(dayjs(detail.ertsDate));
											setNewStatus(detail.status);
											setNewNotes(detail.notes);
										}}
									>
										<Restore />
									</IconButton>
								</Tooltip>
							) : (
								<Tooltip title="Delete">
									<IconButton sx={{ m: 1 }} onClick={() => deleteDetail(detail._id)}>
										<Delete />
									</IconButton>
								</Tooltip>
							)}
						</Box>
					</>
				) : (
					<Typography
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							m: 1,
						}}
					>
						{newNotes}
					</Typography>
				)}
			</TableCell>
			<Modal open={showImagesModal.length > 0} onClose={() => setShowImagesModal([])}>
				<Box
					sx={{
						position: "absolute",
						top: "30px",
						maxHeight: "calc(100% - 60px)",
						width: "calc(100% - 120px)",
						maxWidth: "800px",
						right: "60px",
						boxShadow: 24,
						background: (theme) => theme.palette.background.paper,
						overflow: "auto",
						borderRadius: "10px",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Tooltip title="Close Window">
						<IconButton onClick={() => setShowImagesModal([])} sx={{ color: "inherit", position: "fixed", top: "35px", right: "65px" }}>
							<Close />
						</IconButton>
					</Tooltip>
					{showImagesModal.map((image) => (
						<img src={image} style={{}} />
					))}
				</Box>
			</Modal>
		</TableRow>
	);
}

interface DetailsGridProps extends SessionStateHandler {
	id: string;
	settings: SettingsObject;
	setPostStatus: (arg0: QueryStatus) => void;
	declareEdited: (arg0: string, arg1: boolean) => void;
}

export function DetailsGrid({ id, sessionState, setSessionState, setPostStatus, declareEdited, settings }: DetailsGridProps) {
	const [addDetailDialog, setAddDetailDialog] = useState(false);
	const [deleteDialog, setDeleteDialog] = useState<string | null>(null);
	const [direction, setDirection] = useState<"up" | "down">("down");

	const [inspectionDate, setInspectionDate] = useState<null | Dayjs>(null);
	const [inspector, setInspector] = useState("");
	const [ertsDate, setErtsDate] = useState<null | Dayjs>(null);
	const [status, setStatus] = useState("");
	const [notes, setNotes] = useState("");

	const [images, setImages] = useState<string[]>([]);

	const detailsQuery = useQuery({
		queryKey: ["details", id],
		queryFn: () =>
			getDetails(
				{
					archived: false,
					propertyIds: [id],
				},
				sessionState,
				setSessionState
			),
		enabled: true,
	});

	useEffect(() => {
		if (sessionState.status === SessionStatus.LOGGED_IN) {
			detailsQuery.refetch();
		}
	}, [sessionState]);

	const createDetailRow = (detail: Detail, isLast: boolean) => (
		<DetailsRow
			settings={settings}
			declareEdited={declareEdited}
			isLast={isLast}
			key={detail._id}
			detail={detail}
			deleteDetail={(deleteId) => setDeleteDialog(deleteId)}
			updateDetail={(newDetail: Detail) => {
				setPostStatus(QueryStatus.LOADING);
				postAction(
					{
						action: "update-detail",
						id: newDetail._id,
						data: {
							inspectionDate: newDetail.inspectionDate,
							inspector: newDetail.inspector,
							ertsDate: newDetail.ertsDate,
							status: newDetail.status,
							notes: newDetail.notes,
						},
					},
					sessionState,
					setSessionState,
					() => {
						setPostStatus(QueryStatus.SUCCESS);
						detailsQuery.refetch();
						setAddDetailDialog(false);
					},
					(e) => {
						console.error(e);
						setPostStatus(QueryStatus.FAILURE);
					}
				);
			}}
		/>
	);

	const rawDetailList: Detail[] = (detailsQuery.data?.[id] || []).toSorted((a: { inspectionDate: string }, b: { inspectionDate: string }) =>
		direction === "down" ? -dayjs(a.inspectionDate).diff(dayjs(b.inspectionDate)) : dayjs(a.inspectionDate).diff(dayjs(b.inspectionDate))
	);

	return (
		<>
			<Box sx={{ display: "flex", marginBottom: "5px" }}>
				<ToggleButtonGroup sx={{ marginRight: "5px" }} size="small" value={direction} onChange={(_, value) => setDirection(value)} exclusive>
					<Tooltip title="Sort Descending">
						<ToggleButton value="down">
							<ArrowDownward />
						</ToggleButton>
					</Tooltip>
					<Tooltip title="Sort Ascending">
						<ToggleButton value="up">
							<ArrowUpward />
						</ToggleButton>
					</Tooltip>
				</ToggleButtonGroup>
				<Button
					variant="outlined"
					onClick={() => {
						setAddDetailDialog(true);
						setInspectionDate(null);
						setInspector("");
						setErtsDate(null);
						setStatus("");
						setNotes("");
						setImages([]);
					}}
				>
					Add Inspection
				</Button>
			</Box>
			<TableContainer sx={{ borderRadius: "4px", border: (theme) => `1px solid ${theme.palette.grey[300]}`, height: "calc(100% - 80px)" }}>
				<Table
					sx={{
						height: "100%",
						overflow: "auto",
					}}
				>
					<TableHead>
						<TableRow
							sx={{
								borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
							}}
						>
							<TableCell
								sx={{
									borderRight: (theme) => `1px solid ${theme.palette.grey[100]}`,
									borderBottom: "none",
								}}
								width={"15%"}
							>
								Inspection Date
							</TableCell>
							<TableCell
								sx={{
									borderRight: (theme) => `1px solid ${theme.palette.grey[100]}`,
									borderBottom: "none",
								}}
								width={"15%"}
							>
								Inspector
							</TableCell>
							<TableCell
								sx={{
									borderRight: (theme) => `1px solid ${theme.palette.grey[100]}`,
									borderBottom: "none",
								}}
								width={"15%"}
							>
								Expected Return Date
							</TableCell>
							<TableCell
								sx={{
									borderRight: (theme) => `1px solid ${theme.palette.grey[100]}`,
									borderBottom: "none",
								}}
								width={"15%"}
							>
								Status
							</TableCell>
							<TableCell
								sx={{
									borderBottom: "none",
								}}
								width={"40%"}
							>
								Notes
							</TableCell>
						</TableRow>
					</TableHead>
					{rawDetailList.length + rawDetailList.length === 0 ? (
						<Typography
							sx={{
								color: (theme) => theme.palette.text.secondary,
								marginTop: "40px",
								marginBottom: "40px",
								display: "flex",
								justifyContent: "center",
							}}
						>
							No Inspections
						</Typography>
					) : (
						<>{rawDetailList.map((detail, i) => createDetailRow(detail, i === rawDetailList.length - 1))}</>
					)}
				</Table>
			</TableContainer>
			<Dialog open={addDetailDialog} onClose={() => setAddDetailDialog(false)}>
				<DialogTitle>Add Inspection</DialogTitle>
				<DialogContent>
					<Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
						<FormControl sx={{ m: 1, width: 240 }}>
							<DateTimePicker label="Inspection Date" value={inspectionDate} onChange={setInspectionDate} />
						</FormControl>
						<FormControl sx={{ m: 1, width: 240 }}>
							<TextField select label="Inspector" value={inspector} onChange={(e) => setInspector(e.target.value)}>
								{settings.inspectorList.map((name) => (
									<MenuItem value={name}>{name}</MenuItem>
								))}
							</TextField>
						</FormControl>
						<FormControl sx={{ m: 1, width: 240 }}>
							<DateTimePicker label="Expected Return-to-Service Date" value={ertsDate} onChange={setErtsDate} />
						</FormControl>
						<FormControl sx={{ m: 1, width: 240 }}>
							<TextField select label="Status" value={status} onChange={(e) => setStatus(e.target.value)}>
								{settings.statusList.map((name) => (
									<MenuItem value={name}>{name}</MenuItem>
								))}
							</TextField>
						</FormControl>
						<FormControl sx={{ m: 1, width: 540 }}>
							<TextField label="Notes" variant="filled" multiline value={notes} onChange={(e) => setNotes(e.target.value)}></TextField>
						</FormControl>
						<Box sx={{ borderRadius: "4px", border: (theme) => `1px solid ${theme.palette.grey[300]}`, p: 1 }}>
							<Box>
								<Button variant="outlined" sx={{ padding: 0 }}>
									<label htmlFor="files" style={{ padding: "5px" }}>
										Upload Image
									</label>
								</Button>
								<input
									style={{ visibility: "hidden" }}
									id="files"
									type="file"
									accept="image/png, image/jpg, image/jpeg"
									onChange={(e) => {
										const files = e.target.files;
										const reader = new FileReader();
										reader.onload = () => {
											if (reader.result) {
												if (typeof reader.result === "string") {
													setImages([...images, reader.result]);
												} else {
													const decoder = new TextDecoder("utf-8");
													const data = decoder.decode(reader.result);
													setImages([...images, data]);
												}
											}
										};
										Array.from(files || []).forEach((file) => {
											reader.readAsDataURL(file);
										});
									}}
								/>
							</Box>
							<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
								{images.map((image, i) => (
									<Box sx={{ position: "relative" }}>
										<img style={{ width: "150px", margin: "10px" }} src={image} />
										<IconButton
											sx={{ position: "absolute", top: 0, right: 0 }}
											onClick={() => {
												setImages(images.filter((_, j) => i !== j));
											}}
										>
											<Close />
										</IconButton>
									</Box>
								))}
							</Box>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setAddDetailDialog(false)}>Cancel</Button>
					<Button
						disabled={inspectionDate === null}
						variant="contained"
						onClick={() => {
							setPostStatus(QueryStatus.LOADING);
							postAction(
								{
									action: "add-detail",
									data: {
										propertyId: id,
										archived: false,
										inspectionDate: inspectionDate ? inspectionDate.toISOString() : undefined,
										inspector,
										ertsDate: ertsDate ? ertsDate.toISOString() : undefined,
										status,
										notes,
										images,
									},
								},
								sessionState,
								setSessionState,
								() => {
									setPostStatus(QueryStatus.SUCCESS);
									detailsQuery.refetch();
									setAddDetailDialog(false);
								},
								(e) => {
									console.error(e);
									setPostStatus(QueryStatus.FAILURE);
								}
							);
						}}
					>
						Add
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={deleteDialog !== null} onClose={() => setDeleteDialog(null)}>
				<DialogTitle>{"Are you sure you want to delete this detail?"}</DialogTitle>
				<DialogActions>
					<Button onClick={() => setDeleteDialog(null)}>Cancel</Button>
					<Button
						autoFocus
						variant="contained"
						onClick={() => {
							setPostStatus(QueryStatus.LOADING);
							postAction(
								{
									action: "delete-detail",
									id: deleteDialog,
								},
								sessionState,
								setSessionState,
								() => {
									setPostStatus(QueryStatus.SUCCESS);
									detailsQuery.refetch();
									setDeleteDialog(null);
								},
								(e) => {
									console.error(e);
									setPostStatus(QueryStatus.FAILURE);
								}
							);
						}}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
