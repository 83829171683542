import { Box, Modal } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import "./App.css";
import { Login } from "./Login";
import { Properties } from "./Properties";
import { getSettings, SessionState, SessionStatus } from "./utils";

function App() {
	const [sessionState, setSessionState] = useState<SessionState>(
		localStorage.getItem("token") !== null
			? {
					status: SessionStatus.LOGGED_IN,
					token: localStorage.getItem("token") as string,
			  }
			: {
					status: SessionStatus.LOGGED_OUT,
			  }
	);

	const settingsQuery = useQuery({
		queryKey: ["settings"],
		queryFn: () => getSettings(sessionState, setSessionState),
		enabled: false,
	});

	useEffect(() => {
		if (sessionState.status === SessionStatus.LOGGED_IN) {
			settingsQuery.refetch();
		}
	}, [sessionState]);

	if (sessionState.status === SessionStatus.LOGGED_OUT) {
		return (
			<Box className="App" sx={{ display: "flex" }}>
				<Modal open={true}>
					<Login sessionState={sessionState} setSessionState={setSessionState} />
				</Modal>
			</Box>
		);
	}

	return (
		<Box className="App">
			<Properties
				settings={settingsQuery.data || {}}
				refetchSettings={() => settingsQuery.refetch()}
				sessionState={sessionState}
				setSessionState={setSessionState}
			/>
			<Modal open={sessionState.status === SessionStatus.EXPIRED}>
				<Login sessionState={sessionState} setSessionState={setSessionState} />
			</Modal>
		</Box>
	);
}

export default App;
