import { Close, Delete } from "@mui/icons-material";
import { Alert, AppBar, Box, Button, Dialog, DialogActions, DialogTitle, IconButton, LinearProgress, Modal, Toolbar, Tooltip, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { DetailsGrid } from "./DetailsGrid";
import { getProperty, postAction, QueryStatus, SessionStateHandler, SessionStatus, SettingsObject } from "./utils";

interface DetailsProps extends SessionStateHandler {
	detailsModal: boolean;
	settings: SettingsObject;
	id: string;
	onClose: () => void;
}

export function Details({ detailsModal, id, onClose, sessionState, setSessionState, settings }: DetailsProps) {
	const [postStatus, setPostStatus] = useState<QueryStatus>(QueryStatus.IDLE);

	const [editedCategories, setEditedCategories] = useState<string[]>([]);
	const [editedDialog, setEditedDialog] = useState(false);

	const propertyQuery = useQuery({
		queryKey: ["property", id],
		queryFn: () => getProperty(id, sessionState, setSessionState),
		enabled: false,
	});

	useEffect(() => {
		if (sessionState.status === SessionStatus.LOGGED_IN) {
			if (id !== "") {
				propertyQuery.refetch();
			}
		}
	}, [sessionState, id]);

	const isLoading = postStatus === QueryStatus.LOADING || propertyQuery.isFetching;
	const isError = postStatus === QueryStatus.FAILURE || propertyQuery.isError;

	const declareEdited = (id: string, status: boolean) => {
		if (status) {
			if (!editedCategories.includes(id)) {
				setEditedCategories([...editedCategories, id]);
			}
		} else {
			if (editedCategories.includes(id)) {
				const newArray = [...editedCategories];
				newArray.splice(editedCategories.indexOf(id), 1);
				setEditedCategories(newArray);
			}
		}
	};

	return (
		<Modal open={detailsModal} onClose={() => (editedCategories.length > 0 ? setEditedDialog(true) : onClose())} sx={{ color: "inherit" }}>
			<Box
				sx={{
					position: "absolute",
					top: "15px",
					maxHeight: "calc(100% - 30px)",
					left: "30px",
					right: "30px",
					boxShadow: 24,
					background: (theme) => theme.palette.background.paper,
					overflow: "auto",
					borderRadius: "5px",
				}}
			>
				<Box
					sx={{
						minWidth: "1200px",
					}}
				>
					<AppBar color="inherit" position="sticky">
						<Toolbar>
							{/*<IconButton
								onClick={() => {
									setPostStatus(QueryStatus.LOADING);
									postAction(
										{
											action: "delete-property",
											id,
										},
										sessionState,
										setSessionState,
										() => {
											setPostStatus(QueryStatus.SUCCESS);
											onClose();
										},
										(e) => {
											console.error(e);
											setPostStatus(QueryStatus.FAILURE);
										}
									);
								}}
							>
								<Delete />
							</IconButton>*/}
							<Box sx={{ flexGrow: 1 }}>
								<Typography variant="h6" sx={{ display: "inline-block", padding: "10px" }}>
									{propertyQuery?.data?.["Property Name"]}
								</Typography>
							</Box>
							<Tooltip title="Close Window">
								<IconButton onClick={() => (editedCategories.length > 0 ? setEditedDialog(true) : onClose())} sx={{ color: "inherit" }}>
									<Close />
								</IconButton>
							</Tooltip>
						</Toolbar>
						{isLoading && <LinearProgress sx={{ position: "absolute", width: "100%", bottom: "-4px" }} />}
						{isError && (
							<Alert variant="filled" severity="error" onClose={() => setPostStatus(QueryStatus.IDLE)}>
								An error occurred, please try again.
							</Alert>
						)}
					</AppBar>
					<Box>
						<Box
							sx={{
								m: 2,
							}}
						>
							<DetailsGrid
								id={id}
								settings={settings}
								sessionState={sessionState}
								setSessionState={setSessionState}
								setPostStatus={setPostStatus}
								declareEdited={declareEdited}
							/>
						</Box>
					</Box>
				</Box>
				<Dialog open={editedDialog} onClose={() => setEditedDialog(false)}>
					<DialogTitle>{"You have unsaved changes, are you sure you want to delete them?"}</DialogTitle>
					<DialogActions>
						<Button onClick={() => setEditedDialog(false)}>Cancel</Button>
						<Button
							autoFocus
							variant="contained"
							onClick={() => {
								setEditedDialog(false);
								setEditedCategories([]);
								onClose();
							}}
						>
							Discard Changes
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</Modal>
	);
}
