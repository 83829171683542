import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, LinearProgress, TextField } from "@mui/material";
import { useState } from "react";
import { postAction, QueryStatus, SessionStateHandler } from "./utils";

interface AddPropertyProps extends SessionStateHandler {
	isOpen: boolean;
	onClose: (id?: string) => void;
}

export function AddProperty({ isOpen, onClose, sessionState, setSessionState }: AddPropertyProps) {
	const [ownerName, setOwnerName] = useState("");
	const [ownerPhone, setOwnerPhone] = useState("");
	const [propertyName, setPropertyName] = useState("");

	const [postStatus, setPostStatus] = useState<QueryStatus>(QueryStatus.IDLE);

	const isLoading = postStatus === QueryStatus.LOADING;
	const isError = postStatus === QueryStatus.FAILURE;

	return (
		<Dialog open={isOpen} onClose={() => onClose()}>
			{isLoading && <LinearProgress sx={{ position: "absolute", width: "100%", bottom: "-4px" }} />}
			{isError && (
				<Alert variant="filled" severity="error" onClose={() => setPostStatus(QueryStatus.IDLE)}>
					An error occurred, please try again.
				</Alert>
			)}
			<DialogTitle>Add Property</DialogTitle>
			<DialogContent>
				<Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
					<FormControl sx={{ m: 1, width: 540 }}>
						<TextField label="Property Name" variant="filled" value={propertyName} onChange={(e) => setPropertyName(e.target.value)}></TextField>
					</FormControl>
					<FormControl sx={{ m: 1, width: 540 }}>
						<TextField label="Owner Name" variant="filled" value={ownerName} onChange={(e) => setOwnerName(e.target.value)}></TextField>
					</FormControl>
					<FormControl sx={{ m: 1, width: 540 }}>
						<TextField label="Owner Phone" variant="filled" value={ownerPhone} onChange={(e) => setOwnerPhone(e.target.value)}></TextField>
					</FormControl>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()}>Cancel</Button>
				<Button
					disabled={propertyName === ""}
					variant="contained"
					onClick={() => {
						setPostStatus(QueryStatus.LOADING);
						postAction(
							{
								action: "add-property",
								data: {
									"Owner Name": ownerName,
									"Owner Phone": ownerPhone,
									"Property Name": propertyName,
								},
							},
							sessionState,
							setSessionState,
							({ id }) => {
								setPostStatus(QueryStatus.SUCCESS);
								onClose(id);
							},
							(e) => {
								console.error(e);
								setPostStatus(QueryStatus.FAILURE);
							}
						);
					}}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
}
